"use client";
import { Header } from "@/components/section/header";
import { Nav } from "@/components/section/nav";
import { cn } from "@/lib/utils";
import { ReactNode, useCallback, useRef } from "react";
import {
  ResizablePanel,
  ResizablePanelGroup,
} from "@llamaindex/component/ui/resizable";
import { useAppStore } from "@/store";
import { ImperativePanelGroupHandle } from "react-resizable-panels";

export type BaseLayoutProps = {
  className?: string;
  children: ReactNode;
};

export function BaseLayout(props: BaseLayoutProps) {
  const panelSizes = useAppStore((state) => state.panelSizes);
  const setPanelSizes = useAppStore((state) => state.setPanelSizes);
  const ref = useRef<ImperativePanelGroupHandle>(null);
  return (
    <main className="flex h-screen max-h-screen min-h-screen flex-col overflow-hidden">
      <Header />
      <ResizablePanelGroup
        ref={ref}
        direction="horizontal"
        className="flex h-[calc(100vh-60px)] flex-row bg-gray-50"
        onLayout={useCallback(
          (sizes: number[]) => {
            setPanelSizes(sizes);
          },
          [setPanelSizes],
        )}
      >
        <Nav layoutRef={ref} />
        <ResizablePanel defaultSize={panelSizes[1]} minSize={30}>
          <div
            className={cn(
              "flex h-[calc(100vh-60px)] flex-grow flex-col overflow-auto",
              props.className,
            )}
          >
            {props.children}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </main>
  );
}
