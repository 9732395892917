"use client";
import {
  createProjectApiV1ProjectsPost,
  deleteProjectApiV1ProjectsProjectIdDelete,
  getProjectApiV1ProjectsProjectIdGet,
  listProjectsApiV1ProjectsGet,
} from "@llamaindex/cloud/api";
import {
  QueryClient,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { startTransition } from "react";

export const PROJECT_KEY = "projectInfo";
export const PROJECTS_KEY = "projects";

export function useProjectById({ projectId }: { projectId: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECT_KEY, projectId],
    queryFn: async () => {
      return getProjectApiV1ProjectsProjectIdGet({ projectId });
    },
  });
}

export async function refreshProjectById(
  queryClient: QueryClient,
  projectId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECT_KEY, projectId],
  });
}

export async function refreshProjects(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECTS_KEY],
  });
}

export function useProjects(params?: { organizationId?: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECTS_KEY, params],
    queryFn: async () => listProjectsApiV1ProjectsGet(params),
  });
}

export function useNewProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      organizationId,
    }: {
      name: string;
      organizationId?: string;
    }) => {
      return createProjectApiV1ProjectsPost({
        requestBody: {
          name,
        },
        organizationId,
      });
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
    },
  });
}

export function useDeleteProject() {
  const queryClient = useQueryClient();
  const { data: projects } = useProjects();
  const { replace } = useRouter();
  return useMutation({
    mutationFn: async ({ projectId }: { projectId: string }) => {
      if (projects.length === 1) {
        throw new Error("Cannot delete the last project");
      }
      return deleteProjectApiV1ProjectsProjectIdDelete({ projectId });
    },
    onSuccess: async (_, { projectId }) => {
      await refreshProjects(queryClient);
      const targetProjectId = projects.find(
        (project) => project.id !== projectId,
      )?.id;
      if (targetProjectId) {
        startTransition(() => {
          replace(`/project/${targetProjectId}/pipeline`);
        });
      }
    },
  });
}
