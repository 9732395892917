"use client";
import {
  ColoredBarChart,
  ColoredFileOutput,
  ColoredFolderTree,
  ColoredGrid,
  ColoredSettings,
} from "@/components/icons";
import { DynamicDocumentLink } from "@/components/section/dynamic-document-link";
import { useAuth } from "@/hooks/auth";
import { useProjects } from "@/hooks/project";
import { useUsage } from "@/hooks/usage";
import { LoginProvider } from "@/lib/firebase/auth";
import { assertExists, cn } from "@/lib/utils";
import { useAppStore } from "@/store";
import {
  createCheckoutSessionApiV1BillingCheckoutSessionPost,
  createCustomerPortalSessionApiV1BillingCustomerPortalSessionPost,
} from "@llamaindex/cloud/api";
import { Button } from "@llamaindex/component/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@llamaindex/component/ui/dropdown-menu";
import { Icons } from "@llamaindex/component/ui/icons";
import { ProgressBar } from "@llamaindex/component/ui/progress-bar";
import { ResizablePanel } from "@llamaindex/component/ui/resizable";
import { ToolTipper } from "@llamaindex/component/ui/tooltipper";
import clsx from "clsx";
import { motion } from "framer-motion";
import {
  ArrowRight,
  BarChartBig,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleDollarSignIcon,
  FileOutput,
  FolderTree,
  Grid,
  Info,
  Key,
  Settings,
} from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { RefObject, useCallback, useMemo } from "react";
import { ImperativePanelGroupHandle } from "react-resizable-panels";

interface NavItemProps {
  href: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  label: string;
  isActive: boolean;
  isCollapsed: boolean;
}

const NAV_ITEM_TOOLTIP_DIRECTION = "right";

const NavItem: React.FC<NavItemProps> = ({
  href,
  icon,
  activeIcon,
  label,
  isActive,
  isCollapsed,
}) => {
  const content = (
    <Link href={href}>
      <div
        className={
          "flex cursor-pointer items-center gap-2 rounded-md text-sm transition-all duration-200"
        }
      >
        <div>
          {isActive ? (
            activeIcon
          ) : (
            <div className="group-hover:hidden">{icon}</div>
          )}
        </div>
        {!isCollapsed && (
          <div className="leading-tight hover:text-slate-600">{label}</div>
        )}
      </div>
    </Link>
  );

  return isCollapsed ? (
    <ToolTipper side={NAV_ITEM_TOOLTIP_DIRECTION} content={label}>
      {content}
    </ToolTipper>
  ) : (
    content
  );
};

interface NavSectionProps {
  title: string;
  isCollapsed: boolean;
  children: React.ReactNode;
}
const NavSection: React.FC<NavSectionProps> = ({
  title,
  isCollapsed,
  children,
}) => (
  <div
    className={clsx(
      "mb-4 flex w-full flex-col gap-2",
      isCollapsed && "items-center",
    )}
  >
    <div
      className={clsx(
        "text-2-75xs uppercase tracking-wide text-slate-400",
        isCollapsed && "invisible",
      )}
    >
      {title}
    </div>
    {children}
  </div>
);

interface NavProps {
  layoutRef: RefObject<ImperativePanelGroupHandle>;
}

const Nav: React.FC<NavProps> = ({ layoutRef }) => {
  const panelSizes = useAppStore((state) => state.panelSizes);
  const isCollapsed = useAppStore((state) => state.isCollapsed);
  const setIsCollapsed = useAppStore((state) => state.setIsCollapsed);

  const { user } = useAuth();
  const { data: userUsage } = useUsage();
  const { data: projects } = useProjects();

  const pathname = usePathname();
  const projectId = useAppStore((store) => store.projectId);
  const currentProject = projects.find((project) => project.id === projectId);

  const isByocModeEnabled =
    process.env.NEXT_PUBLIC_BYOC_MODE_ENABLED === "true";

  const arizeUrl = useMemo(() => {
    switch (user?.lastLoginProvider) {
      case LoginProvider.GITHUB:
        return "https://llamatrace.com/auth/github";
      default:
        return "https://llamatrace.com/auth/google";
    }
  }, [user?.lastLoginProvider]);

  return (
    <ResizablePanel
      defaultSize={panelSizes[0]}
      collapsedSize={1}
      collapsible={true}
      minSize={8}
      maxSize={12}
      onCollapse={() => {
        setIsCollapsed(true);
      }}
      onExpand={() => {
        setIsCollapsed(false);
      }}
      className={cn(
        "relative flex h-[calc(100vh-60px)] flex-col overflow-visible border-r border-r-slate-200 bg-gray-50 p-4",
        isCollapsed
          ? "min-w-16 transition-all duration-300 ease-in-out"
          : "w-[200px]",
      )}
      style={{
        overflow: "visible",
      }}
    >
      <Button
        asChild
        variant="outline"
        size="icon"
        className={cn(
          "absolute right-0 top-6 z-10 -mr-3 size-6 -translate-y-1/2 p-1",
        )}
        onClick={useCallback(() => {
          if (layoutRef.current) {
            const [l] = layoutRef.current.getLayout() as [number, number];
            layoutRef.current.setLayout(l <= 10 ? [33, 67] : [1, 99]);
          }
        }, [layoutRef])}
      >
        <motion.button layout="position">
          {isCollapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
        </motion.button>
      </Button>
      <div
        className={clsx("flex flex-col gap-2", isCollapsed && "items-center")}
      >
        <NavSection title="Tools" isCollapsed={isCollapsed}>
          <NavItem
            href="/parse"
            icon={<Grid className="inline size-4" />}
            activeIcon={<ColoredGrid className="inline size-4" />}
            label="Parse"
            isActive={pathname === "/parse"}
            isCollapsed={isCollapsed}
          />
          {user?.allowed.index && projectId && (
            <NavItem
              href={`/project/${projectId}/pipeline`}
              icon={<FolderTree className="inline size-4" />}
              activeIcon={<ColoredFolderTree className="inline size-4" />}
              label="Index"
              isActive={pathname === `/project/${projectId}/pipeline`}
              isCollapsed={isCollapsed}
            />
          )}
          {user?.allowed.eval && projectId && (
            <NavItem
              href={`/project/${projectId}/evals`}
              icon={<BarChartBig className="inline size-4" />}
              activeIcon={<ColoredBarChart className="inline size-4" />}
              label="Evals"
              isActive={pathname === `/project/${projectId}/evals`}
              isCollapsed={isCollapsed}
            />
          )}
          {user?.allowed.extraction && projectId && !isByocModeEnabled && (
            <NavItem
              href={`/project/${projectId}/extraction`}
              icon={<FileOutput className="inline size-4" />}
              activeIcon={<ColoredFileOutput className="inline size-4" />}
              label="Extraction (beta)"
              isActive={pathname === `/project/${projectId}/extraction`}
              isCollapsed={isCollapsed}
            />
          )}
        </NavSection>

        <NavSection title="Resources" isCollapsed={isCollapsed}>
          {currentProject && (
            <NavItem
              href={`/organization/${currentProject.organization_id}/settings/organization`}
              icon={<Settings className="inline size-4" />}
              activeIcon={<ColoredSettings className="inline size-4" />}
              label="Settings"
              isActive={
                pathname.startsWith(`/organization`) &&
                pathname.includes("settings")
              }
              isCollapsed={isCollapsed}
            />
          )}
          <NavItem
            href="/api-key"
            icon={<Key className="inline size-4" />}
            activeIcon={<Key className="inline size-4" />}
            label="API Key"
            isActive={pathname === "/api-key"}
            isCollapsed={isCollapsed}
          />
          <DynamicDocumentLink isCollapsed={isCollapsed} />

          <DropdownMenu>
            <DropdownMenuTrigger>
              <div>
                {isCollapsed ? (
                  <ToolTipper
                    content="Support"
                    side={NAV_ITEM_TOOLTIP_DIRECTION}
                  >
                    <Info className="inline size-4" />
                  </ToolTipper>
                ) : (
                  <span className="flex cursor-pointer items-center gap-2 rounded-md text-sm transition-all duration-200">
                    <div className="flex items-center gap-2">
                      <Info className="inline size-4" />
                      Support
                    </div>
                    <ChevronDown className="inline size-4" />
                  </span>
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent side={"bottom"}>
              <DropdownMenuItem>
                <Link href="https://www.llamaindex.ai/contact">Email us</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="https://llamaindex.statuspage.io">Status page</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="https://docs.cloud.llamaindex.ai/llamaparse/usage_data">
                  Pricing info
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </NavSection>

        {!isByocModeEnabled && (
          <NavSection title="Partner Integrations" isCollapsed={isCollapsed}>
            <NavItem
              href={arizeUrl}
              icon={<Icons.arize className="inline size-4" />}
              activeIcon={<Icons.arize className="inline size-4" />}
              label="LlamaTrace with Arize"
              isActive={false}
              isCollapsed={isCollapsed}
            />
          </NavSection>
        )}
      </div>

      <div className="mt-auto">
        <PlanNav
          isCollapsed={isCollapsed}
          user={user}
          userUsage={userUsage}
          isByocModeEnabled={isByocModeEnabled}
        />
      </div>
    </ResizablePanel>
  );
};

interface PlanNavProps {
  isCollapsed: boolean;
  user: any; // Replace 'any' with your user type
  userUsage: any; // Replace 'any' with your userUsage type
  isByocModeEnabled: boolean;
}

const PlanNav: React.FC<PlanNavProps> = ({
  isCollapsed,
  user,
  userUsage,
  isByocModeEnabled,
}) => {
  let redirectUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;
  if (!redirectUrl && process.env.NEXT_PUBLIC_VERCEL_URL) {
    redirectUrl = process.env.NEXT_PUBLIC_VERCEL_URL;
    if (!redirectUrl.startsWith("https://")) {
      redirectUrl = `https://${redirectUrl}`;
    }
  }

  const redirectToCheckout = async () => {
    assertExists(redirectUrl, "redirectUrl is undefined");
    window.location.href =
      await createCheckoutSessionApiV1BillingCheckoutSessionPost({
        requestBody: {
          success_url: redirectUrl,
          cancel_url: redirectUrl,
        },
      });
  };

  const redirectToCustomerPortal = async () => {
    assertExists(redirectUrl, "redirectUrl is undefined");
    window.location.href =
      await createCustomerPortalSessionApiV1BillingCustomerPortalSessionPost({
        requestBody: {
          return_url: redirectUrl,
        },
      });
  };

  if (isByocModeEnabled) return null;

  if (isCollapsed) {
    return (
      <div className="mb-8 flex flex-col items-center gap-2">
        <ToolTipper
          content={user?.parsePremium ? "Premium Plan" : "Free Plan"}
          side={NAV_ITEM_TOOLTIP_DIRECTION}
        >
          <CircleDollarSignIcon size={16} />
        </ToolTipper>
      </div>
    );
  }

  return (
    <div className="mb-8 flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <div className="mb-2 text-2-75xs uppercase tracking-wide text-slate-400">
          Your info
        </div>
        <div className="flex flex-col gap-2 text-xs">
          <div className="flex items-center gap-1.5 text-sm">
            <CircleDollarSignIcon size={16} />
            {user?.parsePremium ? "Premium Plan" : "Free Plan"}
          </div>
          {user?.parsePremium ? (
            <div className="ml-6 flex flex-col gap-2 text-xs">
              <div className="flex flex-col text-xs">
                {userUsage.usage_pdf_pages.toLocaleString()} Credits today
                <span className="text-2-75xs text-slate-400">
                  Unlimited usage
                </span>
              </div>
            </div>
          ) : (
            <>
              <ProgressBar
                max={userUsage.max_pdf_pages as number}
                value={userUsage.usage_pdf_pages}
                unit="pages per day"
              />
              <span className="text-xs text-slate-400">
                (1200 pages per file max)
              </span>
            </>
          )}
        </div>
        {!user?.parsePremium && (
          <ToolTipper
            delayDuration={25}
            content={
              <span>
                7,000 free credit per week. Then $0.003 <br /> per credit ($3
                per 1,000 credits).
                <br />
              </span>
            }
          >
            <div className="flex items-center justify-between gap-1.5">
              <div className="mt-2 grow">
                <Button
                  onClick={redirectToCheckout}
                  className="h-8 w-full text-xs"
                >
                  Upgrade Plan <ArrowRight className="ml-1 inline size-4" />
                </Button>
              </div>
              <div className="rounded-md p-1 hover:bg-gray-200">
                <Info className="size-4" />
              </div>
            </div>
          </ToolTipper>
        )}
        {user?.parsePremium && (
          <div
            className="cursor-pointer text-xs text-slate-400 underline transition duration-300 hover:text-slate-600"
            onClick={redirectToCustomerPortal}
          >
            Manage plan
          </div>
        )}
        {user?.parsingGrant !== undefined && (
          <div className="flex items-center text-xs">
            <span className="flex flex-col text-xs">
              <span>Grant</span>
              {user.parsingGrant} credits left
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export { Nav };
