"use client";
import { useNewOrganization } from "@/hooks/organization";
import { refreshProjects, useProjects } from "@/hooks/project";
import { ERROR_TOAST_DURATION_MS } from "@/lib/constants";
import { Button } from "@llamaindex/component/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { toast } from "@llamaindex/component/ui/use-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { useEffect, useState, type JSX } from "react";
import { useFormState } from "react-dom";

interface CreateOrganizationButtonProps {
  className?: string;
}

export function CreateOrganizationButton({
  className,
}: CreateOrganizationButtonProps) {
  const { mutateAsync } = useNewOrganization();
  const { data: projects } = useProjects();
  const [createdOrganizationId, setCreatedOrganizationId] = useState<
    string | null
  >(null);
  const { replace } = useRouter();
  useEffect(() => {
    if (createdOrganizationId) {
      const defaultProject = projects.find(
        (p) => p.organization_id === createdOrganizationId,
      );
      if (defaultProject) {
        replace(`/project/${defaultProject.id}/pipeline`);
      }
    }
  }, [createdOrganizationId, projects, replace]);
  const queryClient = useQueryClient();

  const [button, action] = useFormState<JSX.Element, FormData>(
    async (state, payload) => {
      const organizationName = payload.get("organization-name");
      if (typeof organizationName === "string" && organizationName) {
        await mutateAsync({
          name: organizationName,
        })
          .then(async (organization) => {
            if (organization) {
              await refreshProjects(queryClient);
              setCreatedOrganizationId(organization.id);
            } else {
              toast.error("Organization could not be created");
            }
          })
          .catch((error) => {
            toast.error(
              "Error when creating organization: " +
                (error.body?.detail ?? error.message),
              { duration: ERROR_TOAST_DURATION_MS },
            );
            return null;
          });
      }
      return state;
    },
    <Button
      variant="outline"
      className="flex w-full gap-2 whitespace-nowrap text-sm"
    >
      Create Organization
    </Button>,
  );
  return (
    <Dialog>
      <DialogTrigger asChild>{button}</DialogTrigger>
      <DialogContent>
        <DialogHeader>New Organization</DialogHeader>
        <form action={action}>
          <Input name="organization-name" placeholder="Organization Name" />
          <DialogFooter className="mt-4">
            <DialogClose asChild>
              <Button type="submit">Create</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
