"use client";
import { usageApiV1ParsingUsageGet } from "@llamaindex/cloud/api";
import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";

export const USAGE_KEY = "usageInfo";

export function useUsage() {
  return useSuspenseQuery({
    queryKey: [USAGE_KEY],
    queryFn: async () => {
      return usageApiV1ParsingUsageGet();
    },
  });
}

export async function refreshUsageQuery(queryClient: QueryClient) {
  return await queryClient.invalidateQueries({
    queryKey: [USAGE_KEY],
  });
}
